header {
    width: 100%;
    height: 100vh;
    background: 
        linear-gradient(
            to right, 
            rgb(0 0 0 / 0.9), 
            rgb(0 0 0 / 0.2)
        ), 
        url(../../../public/img/bg1.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-color: #1f1f23;
    color: #fff;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.brand {
    position: absolute;
    background: none;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.brand h1,
.brand h2 {
    font-size: 4.5em;
    font-family: 'Audiowide', cursive;
    text-align: center;
    font-weight: 100;
    animation: neon 5s infinite;
}

figcaption {
    display: flex;
    justify-content: center;
    align-items: center;
}

p {
    font-size: 1.5em;
}

header .Typewriter {
    font-family: 'Audiowide', cursive;
    text-align: center;
    font-size: 2em;
    color: #fff;
}

header section {
    position: relative;
}

header svg {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: 10px;
    transform: translate(-50%, -50%);
    font-size: 4em;
    animation: move 2s infinite;
}

@keyframes move {
    0% {   
        margin-top: 0;
    }
    50% {
        margin-top: 90px
    }
    100% {
        margin-top: 0;
    }
}

@media (max-width: 768px) {
    .brand h1,
    .brand h2 {
        font-size: 1.5em;
    }

    .Typewriter {
        font-size: 1.5em;
    }

    header svg {
        margin-top: 20px;
        font-size: 3em;
    }
}