.iaCards {
    margin: 1em auto;
    padding: 1em;
    border: none;
}

.modal {
    padding: 0!important;
}

.modal-content {
    background: none;
    border: none;
}

.iaCards .card,
.modal .card {
    font-family: 'Audiowide', cursive;
    border-radius: 1em;
    border: none;
    overflow: hidden;
    color: #39a9ea;
    font-size: 0.9em;
    font-weight: 400;
    transition: all 600ms ease-in-out;
}

.modal .card {
    margin: 2em auto;
}

.iaCards .card img {
    object-fit: cover;
    object-position: center;
    height: 500px;
    cursor: pointer;
}

.close {
    border: none;
    background: none;
    color: #fff;
    font-size: 1em;
    text-align: start;
    text-shadow: 0 5px 10px  rgb(0, 135, 0);
}