body {
    background-color: #101014;
}

::-webkit-scrollbar {
    height: 10px;
    width: 10px;
    background: #282c34;
}

::-webkit-scrollbar-thumb {
    background: #39a9ea;
    border-radius: 50px;
}

a {
    text-decoration: none;
}

@keyframes shadow {
    0% {   
        box-shadow: 0 0 10px 2px  rgb(57, 169, 234, 0.7);
    }
    25% {
        box-shadow: 0 0 25px 2px  rgb(57, 169, 234, 0.7);
    }
    50% {   
        box-shadow: 0 0 10px 2px  rgb(57, 169, 234, 0.7);
    }
    75% {
        box-shadow: 0 0 25px 2px  rgb(57, 169, 234, 0.7);
    }
    100% {
        box-shadow: 0 0 10px 2px  rgb(57, 169, 234, 0.7);
    }
}

@keyframes neon {
    0% {
        text-shadow: 0 0 5px rgb(57, 169, 234, 0.7),
                     0 0 10px rgb(57, 169, 234, 0.7),
                     0 0 15px rgb(57, 169, 234, 0.7)
    }
    25% {
        text-shadow: 0 0 20px rgb(57, 169, 234, 0.7),
                     0 0 25px rgb(57, 169, 234, 0.7),
                     0 0 30px rgb(57, 169, 234, 0.7)
    }
    50% {   
        text-shadow: 0 0 35px rgb(57, 169, 234, 0.7),
                     0 0 40px rgb(57, 169, 234, 0.7),
                     0 0 35px rgb(57, 169, 234, 0.7)
    }
    75% {
        text-shadow: 0 0 30px rgb(57, 169, 234, 0.7),
                     0 0 25px rgb(57, 169, 234, 0.7),
                     0 0 20px rgb(57, 169, 234, 0.7),
    }
    100% {
        text-shadow: 0 0 15px rgb(57, 169, 234, 0.7),
                     0 0 10px rgb(57, 169, 234, 0.7),
                     0 0 5px rgb(57, 169, 234, 0.7);
    }
}