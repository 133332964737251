.nav {
    width: 90%;
    background: rgba(0, 0, 0, 0.9);
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.9);
    padding: 0.3em 2em;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    border-radius: 50px;
    z-index: 5;
    transition: all 600ms ease-in-out;
}

.navbar {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: #fff;
}

.navbar a,
.navbar-responsive a {
    font-family: 'Audiowide', cursive;
	color: #fff;
	padding: 0.3rem 0;
	margin: 0.5rem 1rem;
	text-decoration: none;
    border-bottom: solid 2px transparent;
	position: relative;
}

.navbar a:after,
.navbar-responsive a:after {
	background: none repeat scroll 0 0 transparent;
	bottom: -0.1em;
	content: "";
	display: block;
	height: 2px;
	left: 50%;
	right: 50%;
	position: absolute;
	background: #fff;
	transition: width 0.6s ease 0s, left 0.6s ease 0s, right 0.6s ease 0s;
	width: 0;
    animation: neon 5s infinite;
}

.navbar a:hover:after,
.navbar-responsive a:hover:after {
	width: 100%;
	left: 0;
	right: 0;
    animation: neon 5s infinite;
}

.nav a.selected {
    border-bottom: solid 2px #fff;
    animation: neon 5s infinite;
}

.lang {
    width: 290px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.lang button,
.nav-responsive section button {
    margin: 0 1em;
}

.spain {
    aspect-ratio: attr(width) / attr(height);
    background: none;
    border: none;
}

.uk {
    aspect-ratio: attr(width) / attr(height);
    background: none;
    border: none;
}

.portugal {
    aspect-ratio: attr(width) / attr(height);
    background: none;
    border: none;
}

.buttonMenu {
    color: #fafafa;
    border: none;
    font-size: 2em;
    display: none;
    background: none;
}

.buttonMenu:hover {
    color: #fff;
    background: none;
}

.nav-responsive {
    width: 90%;
    height: 60px;
    background: rgba(0, 0, 0, 0.9);
    top: 10px;
    margin: 0 auto;
    z-index: 4;
    border-radius: 50px;
    display: none;
    justify-content: flex-start;
    align-items: flex-start;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.9);
    transition: all 1s ease-in-out;
}

.nav-responsive.open {
    height: 100vh;
}

.navbar-responsive.open {
    visibility: visible;
    opacity: 1;
    transition: all 2s ease-in-out;
}

.navbar-responsive {
    width: 90%;
    margin: 5em auto;
    visibility: hidden;
    opacity: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    transition: all 500ms ease-in-out;
}

.nav-responsive section {
    margin: 1.5em auto;
    justify-content: center;
    align-items: center;
}

.navbar-responsive a {
    font-size: 1.2em;
    color:#fafafa;
    margin: 0.5em 0;
    transition: all 400ms ease-in-out;
}

.navbar-responsive p {
    color: #fafafa;
    font-size: 1em;
    margin: 0 auto;
}

@media (max-width: 1028px) {
    .nav {
        width: 90%;
    }

    .navbar,
    .lang {
        display: none;
    }

    .buttonMenu,
    .nav-responsive {
        display: flex;
    }
}