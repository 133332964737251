.graphicCards {
    margin: 1em auto;
    padding: 1em;
    border: none;
}

.modal {
    padding: 0!important;
}

.modal-content {
    background: none;
    border: none;
}

.graphicCards .card,
.modal .card {
    font-family: 'Audiowide', cursive;
    border-radius: 1em;
    border: none;
    overflow: hidden;
    color: #39a9ea;
    font-weight: 600;
    transition: all 600ms ease-in-out;
}

.modal .card {
    margin: 2em auto;
}

.graphicCards .card img {
    object-fit: cover;
    object-position: center;
    height: 500px;
    cursor: pointer;
}

.graphicCards .card p,
.modal .card p {
    font-family: 'calibri', sans-serif;
    color: #282c34;
    font-size: 1.1em;
}

.graphicCards .card-footer,
.modal .card-footer {
    background: #fff;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.graphicCards .card button,
.modal .card button {
    border: none;
    background: none;
    color: #39a9ea;
    font-size: 1.5em;
}

.close {
    border: none;
    background: none;
    color: #fff;
    font-size: 1em;
    text-align: start;
    text-shadow: 0 5px 10px  rgb(0, 135, 0);
}

@media (max-width: 768px) {
    .graphicCards .card-title,
    .modal .card-title {
        font-size: 1em;
        font-weight: 600;
    }
}