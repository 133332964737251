.ia {
    width: 100%;
    background-color: #101014;
    padding: 3em 1em;
    overflow: hidden;
}

.ia h2 {
    font-family: 'Audiowide', cursive;
    padding: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: 2em;
}

.ia h2::before {
    content: '';
    width: 30px;
    border-bottom: 1px solid #fff;
    margin: auto;
    margin-right: 10px;
}

.ia h2::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #fff;
    margin: auto;
    margin-left: 10px;
}

@media (max-width: 768px) {
    .ia h2 {
        font-size: 1.5em;
    }
}