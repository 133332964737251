.contacts {
    width: 100%;
    height: auto;
    min-height: 100vh;
    background: 
        linear-gradient(
            to right, 
            rgb(0 0 0 / 1),
            rgb(0 0 0 / 0.1)
        ), 
        url(../../../public/img/bg2.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    padding: 5em 1em;
    overflow: hidden;
}

.contacts h1 {
    font-family: 'Audiowide', cursive;
    padding: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: 2em;
}

.contacts h1::before {
    content: '';
    width: 30px;
    border-bottom: 1px solid #fff;
    margin: auto;
    margin-right: 10px;
}

.contacts h1::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #fff;
    margin: auto;
    margin-left: 10px;
}

.contacts .card {
    width: 100%;
    height: 600px;
    border: none;
    background: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: all 500ms ease-in-out;
}

.contacts h2 {
    font-family: 'Audiowide', cursive;
    font-size: 4em;
    color: #fff;
    text-shadow: 
        0 0 35px rgb(57, 169, 234, 0.7),
        0 0 40px rgb(57, 169, 234, 0.7),
        0 0 35px rgb(57, 169, 234, 0.7)
}

.contacts a {
    font-family: 'Audiowide', cursive;
    color: #f5f5f5;
    font-size: 3em;
    margin: 1em 0;
    transition: all 600ms ease-in-out;
}

.contacts a:hover {
    transform: translateY(-2px);
    filter: drop-shadow(0 2px 5px #39a9ea);
    color: #fff;
    text-shadow: 
        0 0 35px rgb(57, 169, 234, 0.7),
        0 0 40px rgb(57, 169, 234, 0.7),
        0 0 35px rgb(57, 169, 234, 0.7)
}

.contacts hr {
    width: 20%;
    color: #fff;
}

.contacts section {
    width: 300px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 500ms ease-in-out;
}

.contacts section a {
    font-size: 3em;
    color: #fff;
    transition: all 500ms ease-in-out;
}

.contacts section a:hover {
    filter: drop-shadow(1px 1px 4px rgb(57, 169, 234));
    transform: scale(1.3);
    color: #fff;
}

.social a:active {
    transform: translateY(0);
    box-shadow: none;
}


@media (max-width: 768px) {
    .contacts h1 {
        font-size: 1.5em;
    }
    
    .contacts h2 {
        font-size: 1.9em;
    }

    .contacts a {
        font-size: 1.2em;
    }
    
    .contacts .card {
        width: 100%;
        height: 250px;
    }
    
}