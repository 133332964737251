.myBusiness {
    width: 100%;
    padding: 2em 0;
    background: #101014;
    overflow: hidden;
}

.myBusiness h2 {
    font-family: 'Audiowide', cursive;
    padding: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: 2em;
}

.myBusiness h2::before {
    content: '';
    width: 30px;
    border-bottom: 1px solid #fff;
    margin-right: 10px;
}

.myBusiness h2::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #fff;
    margin-left: 10px;
}

.myBusiness .card {
    background: #1f1f1f;
    margin: 1em auto;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 5px #39a9ea;
    border-radius: 2em;
    overflow: hidden;
    transition: all 600ms ease-in-out;
    animation: shadow 5s infinite;
}
  
.myBusiness h3 {
    font-family: 'Audiowide', cursive;
    font-size: 2em;
    margin-bottom: 1em;
    color: #fff;
    text-shadow: 
        0 0 35px rgb(57, 169, 234, 0.7),
        0 0 40px rgb(57, 169, 234, 0.7),
        0 0 35px rgb(57, 169, 234, 0.7)
}
  
.myBusiness ul {
    list-style: none;
    padding-left: 1em;
    font-family: 'Audiowide', cursive;
    font-size: 2.6em;
    color: #fff;
    text-shadow: 
        0 0 35px rgb(57, 169, 234, 0.7),
        0 0 40px rgb(57, 169, 234, 0.7),
        0 0 35px rgb(57, 169, 234, 0.7)
}
  
.myBusiness li {
    margin-bottom: 0.5em;
}
  
.myBusiness li::before {
    content: "\2022";
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

.myBusiness li::before {
    color: #39a9ea;
}
  
.myBusiness .card .shadow {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.6);
    opacity: 0;
    transition: all 700ms ease-in-out;
}

.myBusiness a .card img {
    aspect-ratio: attr(width) / attr(height);
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 700ms ease-in-out;
}

.myBusiness a .card h3 {
    font-family: 'Audiowide', cursive;
    color: #fff;
    font-size: 2.2em;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: all 700ms ease-in-out;
}

.myBusiness a .card:hover > img,
.myBusiness a .card:active > img {
    transform: scale(1.5);
    transition: all 700ms ease-in-out;
}

.myBusiness a .card:hover > .shadow,
.myBusiness a .card:active > .shadow {
    opacity: 1;
    transition: all 700ms ease-in-out;
}

.myBusiness a .card:hover > h3,
.myBusiness a .card:active > .h3 {
    opacity: 1;
    transition: all 700ms ease-in-out;
}

.vertical {
    width: 2px;
    height: 300px!important;
    color: #fff;
    margin: 0 auto;
}

@media (max-width: 768px) {
    .myBusiness h2 {
        font-size: 1.5em;
    }
  
    .myBusiness ul {
        font-size: 2em;
    }

    .vertical {
        display: none;
    }
}