.homeContact {
    width: 100%;
    height: auto;
    max-height: 400px;
    background: 
        linear-gradient(
            to right, 
            rgb(0 0 0 / 0),
            rgb(0 0 0 / 1)
        ), 
        url(../../../public/img/bg1.jpg);
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    background-color: #1f1f23;
    color: #fff;
    padding: 3em 1em;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeContact article {
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homeContact h2 {
    font-family: 'Audiowide', cursive;
    color: #fff;
    font-size: 3em;
}

.homeContact .button {
    font-family: 'Audiowide', cursive;
    border: solid 2px #000;
    background: #000;
    color: #fff;
    padding: 0.5em 1em;
    margin: 1em 0;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 500ms ease-in-out;
}

.homeContact .button:hover {
    border: solid 2px #39a9ea;
    background: none;
    color: #fff;
    animation: shadow 5s infinite;
}

@media (max-width: 768px) {
    .homeContact h2 {
        font-size: 1.9em;
    }

    .homeContact .button {
        width: 90%;
    }

    .homeContact article {
        width: 100%;
    }
}