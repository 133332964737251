.social {
    width: 280px;
    height: 30px;
    background-color: #000;
    padding: 2em;
    margin: 0 auto;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 50px;
    z-index: 3;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.9);
}

.social a {
    font-size: 2em;
    color: #fff;
    transition: all 600ms ease-in-out;
}

.social a:hover {
    filter: drop-shadow(1px 1px 4px rgb(57, 169, 234));
    transform: scale(1.3);
    color: #fff;
}

@media (max-width: 768px) {
    .social a {
        font-size: 1.5em;
    }
}