.homeProjects {
    background: 
        linear-gradient(
            to right, 
            rgb(0 0 0 / 1), 
            rgb(0 0 0 / 0)
        ), 
        url(../../../public/img/bg1.jpg);
    background-color: #39a9ea;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    padding: 3em 1em;
    display: flex;
    justify-content: center;
    align-items: center;
}

.homeProjects article {
    width: 65%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
}

.homeProjects h2 {
    font-family: 'Audiowide', cursive;
    color: #fff;
    font-size: 3em;
}

.homeProjects .button {
    width: 100%;
    font-family: 'Audiowide', cursive;
    border: solid 2px #000;
    background: #000;
    color: #fff;
    padding: 0.5em 1em;
    margin: 1em 0;
    font-size: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 500ms ease-in-out;
}

.homeProjects .button:hover {
    border: solid 2px #39a9ea;
    background: none;
    color: #fff;
    animation: shadow 5s infinite;
}

@media (max-width: 768px) {
    .homeProjects h2 {
        font-size: 1.9em;
    }

    .homeProjects article {
        width: 100%;
    }
}