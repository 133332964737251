.graphic {
    width: 100%;
    background-color: #101014;
    padding: 6em 1em;
    overflow: hidden;
}

.graphic h2 {
    font-family: 'Audiowide', cursive;
    padding: 1em;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    color: #fff;
    font-size: 2em;
}

.graphic p {
    font-family: 'Audiowide', cursive;
    color: #fff;
    font-size: 1.5em;
    text-align: center;
}

.graphic h2::before {
    content: '';
    width: 30px;
    border-bottom: 1px solid #fff;
    margin: auto;
    margin-right: 10px;
}

.graphic h2::after {
    content: '';
    flex: 1 1;
    border-bottom: 1px solid #fff;
    margin: auto;
    margin-left: 10px;
}

@media (max-width: 768px) {
    .graphic h2 {
        font-size: 1.5em;
    }

    .graphic p {
        font-size: 1.2em;
    }
}