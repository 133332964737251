.apps-cards {
    margin: 0;
    padding: 0;
}

.apps-cards .card {
    height: 680px;
    font-family: 'Audiowide', cursive;
    background-color: #0e0e12;
    border-radius: 0;
    border: none;
    overflow: hidden;
    color: #fff;
    font-weight: 600;
    margin: 1em auto;
    border: solid 3px #39a9ea;
    transition: all 600ms ease-in-out;
    animation: shadow 5s infinite;
}

.apps-cards .card p {
    font-family: 'calibri', sans-serif;
    color: grey;
    font-size: 1.1em;
}

.apps-cards .card-footer {
    background-color: #0e0e12;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.apps-cards .card a {
    padding: 1em;
    color: #fff;
    font-size: 1.5em;
    transition: all 600ms ease-in-out;
}

.apps-cards .card a:hover {
    text-shadow: 0 0 15px rgb(0, 135, 0, 0.7);
}

.apps-cards .card button {
    border: none;
    background: none;
    color: #fff;
    font-size: 1.5em;
}

@media (max-width: 991px) {
    .apps-cards .card {
        height: 750px;
    }
}

@media (max-width: 767px) {
    .apps-cards .card {
        height: auto;
    }
    .apps-cards .card-title {
        font-size: 1em;
        font-weight: 600;
    }
}